import React, { useEffect, useContext } from "react"
import { Box, Heading, Text } from "theme-ui"
import Img from "gatsby-image"
import Table from '../components/table'
import BlueberryGallery from '../components/blueberrygallery'
import CircularBenefits from '../components/circularbenefits'
import FullImage from '../components/fullimage'
import LinkBox from '../components/linkbox'
import { handleBenefits } from '../components/helpers'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { SplitText } from "gsap/SplitText"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import { CustomEase } from "gsap/CustomEase"
import { graphql } from 'gatsby'
import GalleryComp from '../components/galleryComp'
import LangContext from "../components/Context/LangContext"
import { handleLinkLanguage } from "../components/helpers"


const BlueberryPage = ({ data }) => {

    const {
        setPage, currentPage, setLang, currentLang
      } = useContext(LangContext)

    const {
        titleRu,
        heroBlueberryImage,
        subtitleRu,
        heroThumb1Image,
        heroThumb2Image,
        fullImage,
        benefits = [],
        linksComponent = [],
        circularBenefits: circbenef = [],
    } = data.allDatoCmsBlueberry.nodes[0]

    const benefitsRemap = benefits.map(x => {
        return {
            title: x.titleRu,
            description: x.descriptionRu,
            triggerlabel: x.triggerlabelRu,
            image: x.image
        }
    })

    const linksComponentRemap = linksComponent.map(x => {
        return {
            title: x.titleRu,
            image: x.image,
            link: handleLinkLanguage(x.link, currentLang),
        }
    })

    const {
        tableTitleRu,
        tableSubtitleRu,
        tableSpecificationRu,
        tableRow
    } = data.allDatoCmsBlueberry.nodes[0]

    const tableRowRemap = tableRow.map(x => {
        return {
            headline: x.headlineRu,
            value: x.valueRu
        }
    })

    const circBenefRemap = circbenef.map(x => {
        return {
            triggerTitle: x.triggerTitleRu,
            title: x.titleRu,
            description: x.descriptionRu
        }
    })

    let tl = gsap.timeline({ paused: true });

     

      useEffect(() => {
        setPage("blueberry")
      }, [])

    useEffect(() => {
        gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, SplitText, CustomEase);
        const customEase = CustomEase.create("custom", "M0,0 C0.084,0.61 0.136,0.696 0.216,0.798 0.283,0.884 0.374,1 1,1 ")
        // ScrollTrigger.create({
        //     trigger: '.hero',
        //     start: 'top top',
        //     end: 'bottom top',
        //     animation: gsap.to('.heroheading', { y: '10%' }),
        //     scrub: 1,
        //     // markers: true
        // })

        // hero
        new SplitText("#mainHeading", {
            type: "lines",
            linesClass: `mainHeadingLine`
        });
        new SplitText("#mainHeading", {
            type: "lines",
            linesClass: "o-hidden"
        });
        new SplitText("#mainDescription", {
            type: "lines",
            linesClass: `mainDescriptionLine`
        });
        new SplitText("#mainDescription", {
            type: "lines",
            linesClass: "o-hidden"
        });

        tl

            .fromTo('.fatberries', { opacity: 0, rotate: 0, stagger: 0.01, scale: 0.8, duration: 5 }, {stagger: 0.01, opacity: 1,rotate: 30,scale: 1})
            .fromTo('.smallberries', { opacity: 0, rotate: 120, stagger: 0.1, scale: 0.8}, {stagger: 0.1, opacity: 1,rotate: 150,scale: 1})
            .from('.mainHeadingLine', { opacity: 0, y: 30, ease: "circ", stagger: 0.05, })
            .from('.mainDescriptionLine', { ease: "circ", opacity: 0, y: 30, stagger: 0.05 },)
            .fromTo('#logo', { opacity: 0, rotate: 360 }, { opacity: 1, rotate: 0 })
            // .delay(2)
            .timeScale(10)
            .play()
        // .fromTo("#farmBoxCrown path", { drawSVG: "50% 50%", opacity: 0, ease: customEase, }, { duration: 1, drawSVG: "100%", ease: customEase, opacity: 1, }, "<")


        ScrollTrigger.create({
            trigger: '.hero',
            start: 'bottom bottom',
            end: 'bottom top',
            animation: gsap.fromTo('.fatberries', { y: 0, rotate: 30, }, { y: 100, rotate: 90, }),
            scrub: 1,
            // markers: true
        })
        ScrollTrigger.create({
            trigger: '.hero',
            start: 'bottom bottom',
            end: 'bottom top',
            animation: gsap.fromTo('.smallberries', { y: 0, rotate: 150, }, { y: "random(50,300)", rotate: 270, }),
            scrub: 1,
            // markers: true
        })
        // ScrollTrigger.create({
        //     trigger: '.farmBg',
        //     start: 'bottom bottom',
        //     end: 'bottom top',
        //     animation: blueberryHeroTimeline,
        //     // scrub: 0,
        //     markers: true
        // })




        ScrollTrigger.create({
            trigger: '.logorotation',
            start: 'top bottom',
            end: 'bottom top',
            animation: gsap.from('.logorotation', { rotation: 360 }),
            scrub: 1,
            // markers: true
        })
        gsap.utils.toArray(".splittext").forEach((text, index) => {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: text,
                    start: 'top bottom',
                    end: 'bottom top',
                    toggleActions: "play none none none"
                }
            });
            new SplitText(text, {
                type: "lines",
                linesClass: `lineChild${index}`
            });
            new SplitText(text, {
                type: "lines",
                linesClass: "o-hidden"
            });

            tl.from(`.lineChild${index}`, { opacity: 0, y: 50, ease: "expo", stagger: 0.05, });
        })

        gsap.utils.toArray(".textimage-img-reverse").forEach((img, index) => {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: img,
                    toggleActions: "restart pause resume reverse",
                }
            })

            tl.from(img, { rotation: -10, ease: "sine", });
        })

        gsap.utils.toArray(".textimage-img").forEach((img, index) => {
            var tl = gsap.timeline({
                scrollTrigger: {
                    trigger: img,

                    toggleActions: "restart pause resume reverse",
                }
            })

            tl.from(img, { rotation: 10, ease: "sine", });
        })


    },
        [])

    return (
        <Box
            sx={{
            }}>
            <Box className='hero'
                sx={{
                    width: "100%",
                    height: ['100vh', '100vh'],
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    overflowY: 'visible',

                }}>
                <Box
                    sx={{
                        overflowX: 'hidden',
                    }}>

                    {/* Hero Slika */}
                    <Box id="232" className='fatberries'
                        sx={{
                            width: ['40%', '40%', '30%'],
                            justifyContent: ['center', 'inherit'],
                            alignItems: ['center', 'inherit'],
                            position: 'absolute',
                            top: ['7%', '30%', '20%'],
                            left: ['5%', '-7%', '7%'],
                            zIndex: 100,
                        }}>

                        < Img fluid={heroBlueberryImage.fluid} />
                    </Box>
                    {/* Hero Slika */}
                    <Box className='smallberries'
                        sx={{
                            width: ['25%', '25%', '19%'],
                            justifyContent: ['center', 'inherit'],
                            alignItems: ['center', 'inherit'],
                            position: 'absolute',
                            top: ['50%', '10%', '5%'],
                            left: ['5%', '5%', '-7%'],
                            transform: 'rotate(70deg)',
                        }}>

                        < Img fluid={heroBlueberryImage.fluid} />
                    </Box>
                    {/* Hero Slika */}
                    <Box className='smallberries'
                        sx={{
                            width: ['40%', '30%', '19%'],
                            justifyContent: ['center', 'inherit'],
                            alignItems: ['center', 'inherit'],
                            position: 'absolute',
                            bottom: ['5%', '15%', '5%'],
                            left: ['10%', '15%', '-7%'],
                            transform: 'rotate(90deg)',
                        }}>

                        < Img fluid={heroBlueberryImage.fluid} />
                    </Box>
                    {/* Hero Slika */}
                    <Box className='smallberries'
                        sx={{
                            width: ['30%', '30%', '10%'],
                            justifyContent: ['center', 'inherit'],
                            alignItems: ['center', 'inherit'],
                            position: 'absolute',
                            top: ['10%', '10%', '-8%'],
                            left: ['70%', '70%', '35%'],
                            transform: 'rotate(270deg)',
                        }}>

                        < Img fluid={heroBlueberryImage.fluid} />
                    </Box>

                    {/* Hero Slika */}
                    <Box className='smallberries'
                        sx={{
                            width: ['25%', '25%', '11%'],
                            justifyContent: ['center', 'inherit'],
                            alignItems: ['center', 'inherit'],
                            position: 'absolute',
                            top: ['70%', '60%', '14%'],
                            right: ['10%', '10%', '-5%'],
                            transform: 'rotate(270deg)',
                        }}>

                        < Img fluid={heroBlueberryImage.fluid} />
                    </Box>

                    {/* Hero Slika */}
                    <Box className='fatberries'
                        sx={{
                            width: ['20%', '20%', '20%'],
                            justifyContent: ['center', 'inherit'],
                            alignItems: ['center', 'inherit'],
                            position: 'absolute',
                            bottom: ['50%', '50%', '10%'],
                            right: ['-5%', '-5%', '-7%'],
                            transform: 'rotate(90deg)',
                        }}>

                        < Img fluid={heroBlueberryImage.fluid} />
                    </Box>

                    {/* Hero Slika */}
                    <Box className='smallberries'
                        sx={{
                            width: ['10%', '10%', '10%'],
                            justifyContent: ['center', 'inherit'],
                            alignItems: ['center', 'inherit'],
                            position: 'absolute',
                            top: ['49%', '36%', '42%'],
                            right: ['26%', '29%', '32%'],
                            transform: 'rotate(290deg)',
                            zIndex: 6,
                        }}>

                        < Img fluid={heroBlueberryImage.fluid} />
                    </Box>
                    {/* Hero Slika */}
                    <Box className='smallberries'
                        sx={{
                            width: ['8%', '8%', '8%'],
                            justifyContent: ['center', 'inherit'],
                            alignItems: ['center', 'inherit'],
                            position: 'absolute',
                            top: ['50%', '37%', '33%'],
                            left: '35%',
                            transform: 'rotate(180deg)',

                        }}>

                        < Img fluid={heroBlueberryImage.fluid} />
                    </Box>
                </Box>

                {/* Heading */}
                <Box className='heroheading'
                    sx={{
                        display: 'flex',
                        position: 'relative',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: ['100%', '100%'],
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',

                    }}>
                    <Box
                        id="logo"
                        sx={{
                            width: '40%',
                            display: 'flex',
                            zIndex: 5,
                            alignItems: ['flex-end', 'flex-end', 'center'],
                            justifyContent: 'center',
                            mb: [5, 4],

                        }}>
                        <svg width="80" height="79" viewBox="0 0 89 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.1215 85.3961C18.8608 77.8558 24.4912 60.9655 23.955 58.251C23.4187 55.5364 17.2522 56.1397 17.2522 56.1397C14.3517 56.983 11.2853 56.7314 8.52432 55.4236C5.76331 54.1159 3.46049 51.8242 1.9698 48.901C-5.0011 35.0267 7.86825 22.359 19.6652 25.0735C19.6652 25.0735 14.8392 9.99285 23.955 3.35736C23.955 3.35736 37.0924 -8.40555 54.7878 12.1041L60.4181 17.5332C63.8632 14.3731 68.1721 12.6473 72.6172 12.6473C77.0623 12.6473 81.3713 14.3731 84.8163 17.5332C95.5408 29.2961 82.6714 45.2816 79.1859 47.6945C81.3947 48.846 83.3561 50.521 84.9358 52.6048C86.5154 54.6885 87.676 57.1318 88.3381 59.7671C89.0002 62.4025 89.148 65.1677 88.7715 67.8734C88.395 70.5791 87.5031 73.1612 86.1569 75.4429C85.1963 77.8203 83.7504 79.909 81.9368 81.539C80.1232 83.169 77.9932 84.2944 75.72 84.8234C73.4468 85.3525 71.0946 85.2704 68.855 84.5838C66.6153 83.8971 64.5514 82.6254 62.8312 80.8719C60.8774 78.6363 58.3422 77.1575 55.5921 76.6494C52.911 77.2526 42.7228 93.8413 30.1215 85.3961Z" fill="white" />
                        </svg>
                    </Box>

                    <Heading
                        id="mainHeading"
                        sx={{
                            width: ['100%', '80%', '55%'],
                            textAlign: 'center',
                            fontSize: ['20vw', 8, '130px'],
                            color: 'white',
                            lineHeight: 1.1,
                            zIndex: 5,
                            mb: [3, 5, 5]


                        }}>
                        {titleRu}
                    </Heading>

                    <Text
                        id="mainDescription"
                        sx={{
                            top: 900,
                            textAlign: 'center',
                            fontSize: [4, 5, 4],
                            color: 'white',

                            mt: [4, 4, 0],
                            zIndex: 5,

                        }}>
                        {subtitleRu}
                    </Text>
                    {/* <Box
                        sx={{
                            pt: '30px',
                            display:['none','block'],
                        }}>
                        <svg width="34" height="45" viewBox="0 0 34 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1.5" y="1.5" width="31" height="42" rx="15.5" stroke="white" stroke-width="3" />
                            <rect x="13" y="9" width="8" height="15" rx="4" fill="white" />
                        </svg>


                    </Box> */}

                </Box>
                {/* Thumb images */}
                {/* <Box
                    sx={{
                        display: 'flex',
                        flexDirection: ['row','column'],
                        position: ['relative','absolute'],
                        width: ['auto','180px'],
                        height: ['auto','90px'],
                        pb: '30px',
                        ml: ['5%','80%'],
                        mt: [0,'25%'],

                    }}>
                    <Box
                        sx={{
                            pb: '10px',
                            height: ['275px','auto'],
                            width: ['275px','auto'],
                            
                        }}>
                        <Img fluid={heroThumb1Image.fluid} />
                    </Box>

                    <Box
                    sx={{
                            pb: '10px',
                            height: ['275px','auto'],
                            width: ['275px','auto'],
                            ml: [3,0],
                        }}>
                        <Img fluid={heroThumb2Image.fluid} />
                    </Box>
                </Box> */}
            </Box>
            <Table 
            tableTitle={tableTitleRu}
            tableSubtitle={tableSubtitleRu}
            tableRow={tableRowRemap}
            ></Table>
            {/* <GalleryComp 
            title="Berrilicious!"
            description=""
            galleryArray={data.allDatoCmsBlueberry.nodes[0].blueberryGallery}/> */}
            {/* <BlueberryGallery data={data}></BlueberryGallery> */}
            {handleBenefits(benefitsRemap)}
            <CircularBenefits arrayData={circBenefRemap}></CircularBenefits>
            {/* <FullImage image={fullImage} /> */}
            
            <LinkBox linksComponent={linksComponentRemap}></LinkBox>
        </Box>
    )
}






export default BlueberryPage
export const query = graphql`
query MyQueryRu {
    allDatoCmsBlueberry {
      nodes {
        subtitleRu
        titleRu
        tableSubtitleRu
        tableTitleRu
        tableRow {
            headlineRu
            valueRu
        }
        heroBlueberryImage {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        blueberryGallery {
          fluid { 
            ...GatsbyDatoCmsFluid
          }
        }

        heroThumb1Image {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        heroThumb2Image {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        circularBenefits {
            triggerTitleRu
            titleRu
            descriptionRu
        }
        fullImage {
            fluid {
                ...GatsbyDatoCmsFluid
            }
        }
        benefits {
            titleRu
            descriptionRu
            image {
                fluid{
                    ...GatsbyDatoCmsFluid
                }
            }
        }
        linksComponent {
            titleRu
            link
            image {
                fluid{
                    ...GatsbyDatoCmsFluid
                }
            }
        }
      }
    }
  }`